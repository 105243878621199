import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/:category/:id',
    name: 'category',
    component: () => import('../views/CategoryView.vue')
  },
  {
    path: '/:category/:id/:filterId',
    name: 'category with id and filter id',
    component: () => import('../views/CategoryView.vue')
  },
  {
    path: '/product/:id',
    name: 'Producto',
    component: () => import('../views/ItemView.vue')
  },
  {
    path: '/ofertas',
    name: 'Ofertas',
    component: () => import('../views/LatestAndSaleView.vue')
  },
  {
    path: '/novedades',
    name: 'Novedades',
    component: () => import('../views/LatestAndSaleView.vue')
  },
  {
    path: '/sobre-nosotros',
    name: 'Sobre Nosotros',
    component: () => import('../views/AboutUsView.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/Cart.vue')
  },
  {
    path: '/order',
    name: 'corder',
    component: () => import('../views/Order.vue')
  },
  {
    path: "/:pathMatch(.*)*",
    name: '404',
    component: function() {
        return import ('../views/404.vue')
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      if (savedPosition) {
        // create an Observer instance
        const resizeObserver = new ResizeObserver(entries => {
          if (entries[0].target.clientHeight >= savedPosition.top + screen.height) {
            resolve(savedPosition);
            resizeObserver.disconnect();
          }
        });

        // start observing a DOM node
        resizeObserver.observe(document.body);
      }
      else {
        resolve({ top: 0 });
      }
    })
  }
})

export default router
