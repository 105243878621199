import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "categoryDropdown relative" }
const _hoisted_2 = {
  key: 0,
  class: "font-semibold uppercase text-emerald-900"
}
const _hoisted_3 = { class: "text-sm font-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "categories flex gap-2 items-center cursor-pointer",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleDropdown()))
    }, [
      _createVNode(_component_Icon, {
        iconImg: "/img/icons/category.svg",
        color: "bg-emerald-900"
      }),
      (_ctx.text)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.text), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_Icon, {
        iconImg: "/img/icons/chevron-down.svg",
        color: "bg-emerald-900"
      })
    ]),
    ((_ctx.options as any).length > 0 && _ctx.showDropdown)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass((_ctx.mode == 'floating') ? 'drowpdown w-[250px] bg-white absolute top-7 left-0 rounded-b-md shadow-md overflow-hidden' : 'mt-4')
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              to: '/categoria/'+(option as any).id,
              class: "border-l-4 border-transparent hover:border-emerald-900 option w-full block py-2 px-4 hover:bg-gray-100 cursor-pointer",
              key: index,
              onClick: _cache[1] || (_cache[1] = ($event: any) => ((_ctx.mode == 'floating') ? _ctx.toggleDropdown() : _ctx.optionClicked()))
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_3, _toDisplayString((option as any).name), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
        ], 2))
      : _createCommentVNode("", true)
  ]))
}