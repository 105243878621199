
//Axios
import axios from "axios";

import { defineComponent } from 'vue'

export default defineComponent({
    name: "SearchBar",
    data() {
        return {
            isSearching: false,
            showSearchBox: false,
            products: [] as any[any]
        }
    },
    methods: {
        async search(text: string) {
            if (text.length > 3) {
                this.products= []
                this.isSearching = true

                this.showSearchBox = true

                //get category with products
                const response = await axios.get('https://admin.alamomuebles.com.py/api/items/products?fields=*,gallery.directus_files_id.data.*&filter[name][like]='+text+'&filter[code][logical]=or&filter[code][like]='+text)
                
                //get products
                this.products = response.data.data

                this.isSearching = false
            }
        },
        closeSearch(){
            this.showSearchBox = false;
            (this.$refs as any).searchInput.value = ''
            this.products= []
        },
        formatPrice(price: number) {
            let localeString = 'es-PY'
            let formattingOptions = {
                style: 'currency',
                currency: 'PYG',
                minimumFractionDigits: 0,
            }

            let formattedPrice = new Intl.NumberFormat(localeString, formattingOptions)
            formattedPrice = formattedPrice.format(price) as any

            return formattedPrice.toString()
        }
    }
})
