
//Custom Components
import Banner from "@/components/BannerComponent.vue"
import ProductCard from "@/components/ProductCard.vue"
import Button from "@/components/Button.vue"

//Axios
import axios from "axios";

//Swiper
import Swiper, { Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

//This View
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',
  data() {
    return {
      loading: true,
      config: [] as any[any],
      categories: [] as any[any],
      latest: [] as any[any],
      indoor: [] as any[any],
      outdoor: [] as any[any]
    }
  },
  components: {
    Banner,
    ProductCard,
    Button
  },
  async created() {
    const config = await axios.get('https://admin.alamomuebles.com.py/api/items/config/1?fields=banner_sale.*.*')
    this.config = config.data.data

    const categories = await axios.get('https://admin.alamomuebles.com.py/api/items/categories?fields=*,image.data.*&filter[featured][neq]=false')
    this.categories = categories.data.data

    const latest = await axios.get('https://admin.alamomuebles.com.py/api/items/products?fields=*,gallery.directus_files_id.data.*&limit=6&sort=-id')
    this.latest = latest.data.data

    const indoor = await axios.get('https://admin.alamomuebles.com.py/api/items/products?fields=*,gallery.directus_files_id.data.*&limit=12&filter[type][eq]=indoor&sort=?')
    this.indoor = indoor.data.data

    const outdoor = await axios.get('https://admin.alamomuebles.com.py/api/items/products?fields=*,gallery.directus_files_id.data.*&limit=6&filter[type][eq]=electro&sort=?')
    this.outdoor = outdoor.data.data

    this.loading = false

    this.$nextTick(() => {
      this.createSwiper()
    })
  },
  methods: {
    createSwiper() {
      //create banners swiper
      const bannersOpts = {
        slidesPerView: 1,
        spaceBetween: 8,
        speed: 500,
        loop: true,
        autoplay: {
          delay: 3000,
        },
        breakpoints: {
          768: {
            slidesPerView: 3,
            spaceBetween: 16,
          }
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        modules: [Autoplay, Navigation]
      }

      new Swiper(this.$refs.banners as any, bannersOpts)
    }
  }
});
