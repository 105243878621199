import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "productCard" }
const _hoisted_2 = { class: "imageContainer relative overflow-hidden aspect-square mb-4" }
const _hoisted_3 = { class: "w-fit bg-emerald-700 px-4 py-1 rounded-t-xl text-xs text-white absolute bottom-0 left-1/2 -translate-x-1/2 z-10" }
const _hoisted_4 = { class: "font-semibold line-through" }
const _hoisted_5 = { class: "image bg-gray-300 aspect-square border border-gray-200 rounded-md overflow-hidden bg-center bg-cover border-b mb-2 hover:scale-105 transition-all" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "text flex flex-col gap-2 text-center bg-white" }
const _hoisted_8 = { class: "font-bold text-sm md:text-base" }
const _hoisted_9 = { class: "font-semibold text-xs text-emerald-900 md:text-sm uppercase h-8 md:h-10 overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.formatPrice(_ctx.price)), 1)
      ], 512), [
        [_vShow, _ctx.sale_price > 0]
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("img", {
          class: "w-full h-full object-cover",
          src: _ctx.image
        }, null, 8, _hoisted_6)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("p", _hoisted_8, _toDisplayString((_ctx.sale_price > 0) ? _ctx.formatPrice(_ctx.sale_price) : _ctx.formatPrice(_ctx.price)), 1),
      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.name), 1)
    ])
  ]))
}